import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import sortBy from 'lodash/sortBy'
import { DispatchUserSortableTicketItem } from './DispatchUserSortableTicketItem'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

export const DispatchUserSortableTicketsList = ({
  tickets,
  onTicketHoverStart,
  onTicketHoverEnd,
  onAssignTicketToUser,
  onChangeSite,
  onSortEnd,
  onSortStart,
  onUpdateTicketFlag,
  reordering
}) => {
  const sortedTickets = useMemo(() => sortBy(tickets, ['position', 'id']), [tickets])

  function getItemStyle (style) {
    if (style?.transform) {
      const axisLockY = `translate(0px, ${style.transform.split(',').pop()}`
      return {
        ...style,
        transform: axisLockY,
        background: 'white'
      }
    }
    return style
  }

  return (
    <DragDropContext onDragEnd={onSortEnd} onDragStart={onSortStart}>
      <Droppable droppableId='droppable'>
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}>
            {sortedTickets.map((value, index) => (
              <Draggable key={value.id} draggableId={value.id.toString()} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={getItemStyle(
                      provided.draggableProps.style
                    )}>
                    <DispatchUserSortableTicketItem
                      dragHandleProps={provided.dragHandleProps}
                      isDragging={snapshot.isDragging}
                      ticket={value}
                      reordering={reordering}
                      onAssignTicketToUser={onAssignTicketToUser}
                      onChangeSite={onChangeSite}
                      onUpdateTicketFlag={onUpdateTicketFlag}
                      onTicketHoverStart={onTicketHoverStart}
                      onTicketHoverEnd={onTicketHoverEnd}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

DispatchUserSortableTicketsList.propTypes = {
  onUpdateTicketFlag: PropTypes.func.isRequired,
  onChangeSite: PropTypes.func.isRequired,
  onAssignTicketToUser: PropTypes.func.isRequired,
  reordering: PropTypes.bool.isRequired,
  onTicketHoverStart: PropTypes.func.isRequired,
  onTicketHoverEnd: PropTypes.func.isRequired,
  tickets: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSortEnd: PropTypes.func.isRequired,
  onSortStart: PropTypes.func.isRequired
}
