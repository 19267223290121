import { loader } from 'graphql.macro'
import { intercomConnection } from '../../intercomConnection'

export default async function createJob ({ dataLoaders, variables }) {
  const schema = loader('./createJob.graphql')
  const jobFields = {
    latitude: variables.latitude,
    longitude: variables.longitude,
    addressline1: variables.displayAddress || variables.addressline1,
    city: variables.city,
    state: variables.state,
    zip: variables.zip,
    country: variables.country,
    notes: variables.notes,
    internalAdminNotes: variables.internalAdminNotes,
    firstName: variables.firstName,
    lastName: variables.lastName,
    email: variables.email,
    primaryPhone: variables.prettyPrimaryPhone,
    placeId: variables.placeId,
    secondaryFirstName: variables.secondaryFirstName,
    secondaryLastName: variables.secondaryLastName,
    secondaryEmail: variables.secondaryEmail,
    secondaryPhone: variables.prettySecondaryPhone,
    addressDetails: variables.addressDetails
  }
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      jobFields,
      clientId: variables.clientId
    }
  })
  intercomConnection.trackEvent('job_created')
  return response.createJob
}
