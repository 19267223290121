import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import useQuery, { generateQueryKey } from '../hooks/useQuery'
import useMutation from '../hooks/useMutation'
import useInfiniteQuery from '../hooks/useInfiniteQuery'
import QUERY_KEYS from '../graphql/queryKeys'
import dispatchPageQuery from '../graphql/queries/pages/dispatchPage'
import dispatchTicketsQuery from '../graphql/queries/dispatchTickets'
import dispatchResourcesQuery from '../graphql/queries/dispatchResources'
import dispatchSitesQuery from '../graphql/queries/dispatchSites'
import dispatchTicketQuery from '../graphql/queries/dispatchTicket'
import saveDriverSortPreferencesMutation from '../graphql/mutations/saveDriverSortPreferences'
import saveUnassignedTicketsSortPreferenceMutation from '../graphql/mutations/saveUnassignedTicketsSortPreference'
import updateTicketFlaggedMutation from '../graphql/mutations/updateTicketFlagged'
import assignTicketToUserMutation from '../graphql/mutations/assignTicketToUser'
import reorderTicketsMutation from '../graphql/mutations/reorderTickets'
import NormalLayoutContainer from './shared/NormalLayoutContainer'
import DispatchTicketSearchForm from '../components/DispatchTicketSearchForm'
import { Spinner } from './shared/Spinner'
import debounce from 'lodash/debounce'
import set from 'lodash/set'
import DispatchTicketsList from '../components/dispatch-tickets-list'
import dayjs from '../utilities/dayjs'
import { StringParam, useQueryParams } from 'use-query-params'
import { useQueryClient } from 'react-query'
import notify from '../utilities/notify'
import { captureErrorAndNotify } from '../utilities/errorHandlers'
import DispatchMap from '../components/DispatchMap'
import DispatchInfoBar from '../components/DispatchInfoBar'
import { useChannelEvent } from '../hooks/pusher'

const refetchEverySeconds = 3
const refetchInterval = refetchEverySeconds * 60 * 1000

export default function DispatchPage () {
  const { user, haulerChannel, dataLoaders } = useSelector(({ user, pusher, dataLoaders }) => ({
    user: user.user,
    haulerChannel: pusher.haulerChannel,
    dataLoaders
  }))
  const [showResources, setShowResources] = useState(false)
  const [showSites, setShowSites] = useState(false)
  const [hoverTicketId, setHoverTicketId] = useState()
  const [hoverUserId, setHoverUserId] = useState()
  const [refetchTicketsNeeded, setRefetchTicketsNeeded] = useState(false)
  const queryClient = useQueryClient()

  const [queryParams, setQueryParams] = useQueryParams({
    date: StringParam,
    status: StringParam,
    address: StringParam,
    ticketTypeId: StringParam,
    resourceTypeId: StringParam,
    driverId: StringParam,
    openTicketPanel: StringParam,
    latitude: StringParam,
    longitude: StringParam
  })

  // memoizing not because this is an expensive operation, but because a memoized value is needed to simplify other logic
  const searchFields = useMemo(() => {
    return {
      date: queryParams.date,
      status: queryParams.status,
      address: queryParams.address,
      ticketTypeId: queryParams.ticketTypeId,
      resourceTypeId: queryParams.resourceTypeId,
      driverId: queryParams.driverId
    }
  }, [
    queryParams.date,
    queryParams.status,
    queryParams.address,
    queryParams.ticketTypeId,
    queryParams.resourceTypeId,
    queryParams.driverId
  ])

  const ticketMatchesSearch = useCallback(function ticketMatchesSearch (ticket) {
    return dayjs(searchFields.date).isSame(dayjs(ticket.date)) &&
    (!searchFields.status ||
      searchFields.status === ticket.status ||
      (searchFields.status === 'active' && ['open', 'en_route'].includes(ticket.status)) ||
      (searchFields.status === 'terminal' && ['closed', 'cancelled'].includes(ticket.status))
    ) &&
    (!searchFields.ticketTypeId || searchFields.ticketTypeId === ticket.ticketTypeId) &&
    (!searchFields.resourceTypeId || searchFields.resourceTypeId === ticket.resourceTypeId) &&
    (!searchFields.driverId || searchFields.driverId === ticket.driverId)
  }, [searchFields])

  const dispatchPageQueryKey = [QUERY_KEYS.dispatchPage, {
    haulerId: user.haulerId,
    userId: user.id
  }]
  const { data: pageData, isLoading: isLoadingPageData } = useQuery(
    dispatchPageQueryKey,
    dispatchPageQuery,
    {
      refetchInterval,
      cacheTime: 0,
      onError (error) {
        captureErrorAndNotify(error, 'Failed to fetch data')
      }
    }
  )
  const users = useMemo(() => {
    if (pageData?.users.edges === undefined) {
      return undefined
    }

    return pageData.users.edges.map(edge => edge.node)
  }, [pageData?.users.edges])

  const {
    data: ticketsData,
    fetchNextPage: fetchNextTicketsPage,
    isFetchingNextPage: isFetchingNextTicketsPage,
    hasNextPage: hasNextTicketsPage,
    lastUpdatedAt: ticketsLastUpdatedAt,
    isLoading: isLoadingTickets,
    refetch: refetchTickets,
    isFetching: isFetchingTickets
  } = useInfiniteQuery(
    [
      QUERY_KEYS.dispatchTickets,
      {
        haulerId: user.haulerId,
        searchFields
      }
    ],
    dispatchTicketsQuery,
    {
      getNextPageParam (lastPage) {
        if (!lastPage.ticketsSearch.pageInfo.hasNextPage) {
          return
        }
        return lastPage.ticketsSearch.pageInfo.endCursor
      },
      enabled: Boolean(queryParams.date),
      refetchInterval,
      cacheTime: 0,
      onError (error) {
        captureErrorAndNotify(error, 'Failed to fetch tickets')
      },
      onSuccess () {
        setRefetchTicketsNeeded(false)
      }
    }
  )
  const tickets = useMemo(() => {
    if (!ticketsData) return
    return ticketsData.pages.reduce((acc, page) => {
      const edgePages = page.ticketsSearch.edges.reduce((acc, edge) => [...acc, edge.node], [])
      return [...acc, ...edgePages]
    }, [])
  }, [ticketsData])

  const locationDetailTickets = useMemo(() => {
    if (!queryParams.latitude || !queryParams.longitude || !tickets) return
    return tickets.filter(ticket => ticket?.job?.latitude?.toString() === queryParams.latitude && ticket?.job?.longitude?.toString() === queryParams.longitude)
  }, [tickets, queryParams.latitude, queryParams.longitude])

  const {
    data: resourcesData,
    fetchNextPage: fetchNextResourcesPage,
    isFetchingNextPage: isFetchingNextResourcesPage,
    hasNextPage: hasNextResourcesPage
  } = useInfiniteQuery(
    [
      QUERY_KEYS.resourcesSearch,
      { haulerId: user.haulerId }
    ],
    dispatchResourcesQuery,
    {
      getNextPageParam (lastPage) {
        if (!lastPage.assets.pageInfo.hasNextPage) return
        return lastPage.assets.pageInfo.endCursor
      },
      enabled: showResources,
      refetchInterval,
      cacheTime: 0,
      onError (error) {
        captureErrorAndNotify(error, 'Failed to fetch assets')
      }
    }
  )
  const resources = useMemo(() => {
    if (!resourcesData) return
    return resourcesData.pages.reduce((acc, page) => {
      const edgePages = page.assets.edges.reduce((acc, edge) => [...acc, edge.node], [])
      return [...acc, ...edgePages]
    }, [])
  }, [resourcesData])

  const dispatchSitesQueryKey = [
    QUERY_KEYS.sites,
    { haulerId: user.haulerId }
  ]
  const { data: sitesData } = useQuery(
    dispatchSitesQueryKey,
    dispatchSitesQuery,
    {
      enabled: showSites,
      refetchInterval,
      cacheTime: 0
    }
  )

  const { mutate: saveDriverSortPreferences } = useMutation(saveDriverSortPreferencesMutation, {
    onSuccess (data, variables) {
      queryClient.setQueryData(generateQueryKey(dispatchPageQueryKey, user.id), oldData => ({
        ...(oldData ?? {}),
        driverSortPreferences: variables.driverSortPreferences
      }))
    }
  })

  const { mutate: saveUnassignedTicketsSortPreference } = useMutation(saveUnassignedTicketsSortPreferenceMutation, {
    onSuccess (data, variables) {
      queryClient.setQueryData(generateQueryKey(dispatchPageQueryKey, user.id), oldData => ({
        ...(oldData ?? {}),
        unassignedTicketsSortPreferences: variables.preferences
      }))
    }
  })

  const upsertCachedTicketData = useCallback(function upsertCachedTicketData (newTicketData) {
    const dispatchTicketsQueryKey = [
      QUERY_KEYS.dispatchTickets,
      {
        haulerId: user.haulerId,
        searchFields
      }
    ]
    queryClient.setQueryData(generateQueryKey(dispatchTicketsQueryKey, user.id), oldData => {
      return upsertToCachedPageData(oldData, 'ticketsSearch', newTicketData)
    })
  }, [
    queryClient,
    searchFields,
    user.haulerId,
    user.id
  ])

  const removeCachedTicketData = useCallback(function removeCachedTicketData (removeData) {
    const dispatchTicketsQueryKey = [
      QUERY_KEYS.dispatchTickets,
      {
        haulerId: user.haulerId,
        searchFields
      }
    ]
    queryClient.setQueryData(generateQueryKey(dispatchTicketsQueryKey, user.id), oldData => {
      return removeFromCachedPageData(oldData, 'ticketsSearch', removeData)
    })
  }, [
    queryClient,
    searchFields,
    user.haulerId,
    user.id
  ])

  const upsertCachedUserData = useCallback(function upsertCachedUserData (newUserData) {
    const dispatchPageQueryKey = [QUERY_KEYS.dispatchPage, {
      haulerId: user.haulerId,
      userId: user.id
    }]
    queryClient.setQueryData(generateQueryKey(dispatchPageQueryKey, user.id), oldData => {
      return upsertToCachedEdgeData(oldData, 'users', newUserData, true)
    })
  }, [queryClient, user.haulerId, user.id])

  const { mutate: updateTicketFlagged } = useMutation(updateTicketFlaggedMutation, {
    onSuccess ({ ticket: newTicketData }) {
      upsertCachedTicketData({ [newTicketData.id.toString()]: { flagged: newTicketData.flagged } })
      notify('success', 'Flag status changed')
    },
    onError (error) {
      captureErrorAndNotify(error, 'Error flagging ticket')
    }
  })

  const { mutate: assignTicketToUser } = useMutation(assignTicketToUserMutation, {
    onSuccess (data, variables) {
      upsertCachedTicketData({
        [variables.id.toString()]: {
          driverId: variables.driverId?.toString(),
          driverAssigned: variables.driverId
            ? {
                firstName: data?.data?.ticket?.user?.first_name,
                lastName: data?.data?.ticket?.user?.last_name
              }
            : null
        }
      })
      notify('success', 'Ticket successfully assigned')
    },
    onError (error) {
      captureErrorAndNotify(error, 'Error assigning ticket')
    }
  })

  const { mutate: reorderTickets } = useMutation(reorderTicketsMutation, {
    onError (error) {
      captureErrorAndNotify(error, 'Failed to save reordering of tickets')
    },
    onMutate (variables) {
      upsertCachedTicketData(variables)
    },
    onSuccess () {
      notify('success', 'Tickets reordered successfully!')
    }
  })

  useEffect(function setDefaultQueryParams () {
    const params = {}
    if (!queryParams.date) {
      params.date = dayjs().format('YYYY-MM-DD')
    }
    if (!queryParams.openTicketPanel) {
      params.openTicketPanel = 'unassigned'
    }

    if (Object.keys(params).length === 0) return
    setQueryParams(params)
  }, [queryParams, setQueryParams])

  // The idea here is that if we have more tickets to load then load them, this allows us to use pagination, but also load all the results
  useEffect(function retrieveNextTicketsPage () {
    if (isFetchingNextTicketsPage || !hasNextTicketsPage) {
      return
    }

    fetchNextTicketsPage()
  }, [hasNextTicketsPage, isFetchingNextTicketsPage, fetchNextTicketsPage])

  // The idea here is that if we have more resources to load then load them, this allows us to use pagination, but also load all the results
  useEffect(function retrieveNextResourcesPage () {
    if (isFetchingNextResourcesPage || !hasNextResourcesPage) {
      return
    }

    fetchNextResourcesPage()
  }, [hasNextResourcesPage, isFetchingNextResourcesPage, fetchNextResourcesPage])

  useEffect(function refetchTicketsIfNeeded () {
    const interval = setInterval(() => {
      if (!refetchTicketsNeeded || isFetchingTickets) return
      refetchTickets()
      setRefetchTicketsNeeded(false)
    }, 15000)

    return function cleanupInterval () {
      clearInterval(interval)
    }
  }, [refetchTicketsNeeded, refetchTickets, isFetchingTickets])

  const handleSearchSubmit = debounce(values => {
    setQueryParams({
      date: values.date,
      status: values.status,
      address: values.address,
      ticketTypeId: values.ticketTypeId,
      resourceTypeId: values.resourceTypeId,
      driverId: values.driverId
    })
  }, 500)

  const fetchTicket = useCallback(function fetchTicket (ticketId) {
    if (!dataLoaders) return
    const queryKey = [QUERY_KEYS.dispatchTicket, { ticketId }]
    // Manually calling our query instead of using useQuery. We were running into issues where the onSuccess callback of useQuery
    // would not be called for old queries. i.e. if two tickets changed at the same time, and we fetched them, then we would only
    // receive the latest onSuccess callback and the other one would be discarded.
    dispatchTicketQuery({ dataLoaders, queryKey })
      .then(({ ticket: fetchedTicket }) => {
        const existingTicket = tickets.find(ticket => ticket.id === fetchedTicket.id)
        const ticketMatchesFilters = ticketMatchesSearch(fetchedTicket)
        if (existingTicket && ticketMatchesFilters) {
          upsertCachedTicketData({ [fetchedTicket.id]: fetchedTicket })
          return
        }

        if (existingTicket && !ticketMatchesFilters) {
          removeCachedTicketData({ [fetchedTicket.id]: fetchedTicket })
          return
        }

        if (!existingTicket && !ticketMatchesFilters) return

        upsertCachedTicketData({ [fetchedTicket.id]: fetchedTicket })
      })
  }, [tickets, ticketMatchesSearch, dataLoaders, removeCachedTicketData, upsertCachedTicketData])

  const handleExternalTicketChange = useCallback(function handleExternalTicketChange ({ ticket }) {
    const ticketData = normalizeTicket(ticket)
    // address is the one thing we can not check because pusher does not return those fields, so we have to refetch all the tickets
    if (searchFields.address) {
      setRefetchTicketsNeeded(true)
      return
    }

    const existingTicket = tickets.find(ticket => ticket.id === ticketData.id)
    const ticketMatchesFilters = ticketMatchesSearch(ticketData)
    if (existingTicket && ticketMatchesFilters) {
      fetchTicket(ticketData.id)
      return
    }

    if (existingTicket && !ticketMatchesFilters) {
      removeCachedTicketData({ [ticketData.id]: ticketData })
      return
    }

    if (!existingTicket && !ticketMatchesFilters) return

    fetchTicket(ticketData.id)
  }, [
    ticketMatchesSearch,
    removeCachedTicketData,
    searchFields,
    tickets,
    fetchTicket
  ])

  const handleExternalUserChange = useCallback(function handleExternalUserChange ({ user: newUser }) {
    const newUserId = newUser.id.toString()
    upsertCachedUserData({
      [newUserId]: {
        id: newUserId,
        firstName: newUser.first_name,
        lastName: newUser.last_name,
        trackLocation: newUser.track_location,
        latitude: newUser.latitude ? parseFloat(newUser.latitude) : null,
        longitude: newUser.longitude ? parseFloat(newUser.longitude) : null,
        lastLocatedAt: newUser.last_located_at
      }
    })
  }, [upsertCachedUserData])

  useChannelEvent(haulerChannel, 'change-ticket', handleExternalTicketChange)
  useChannelEvent(haulerChannel, 'change-user', handleExternalUserChange)

  function handleSaveDriverSortPreferences (driverSortPreferences) {
    return saveDriverSortPreferences({ ownerId: user.id, driverSortPreferences })
  }

  function handleSaveUnassignedTicketsSortPreferences (preferences) {
    return saveUnassignedTicketsSortPreference({ userId: user.id, preferences })
  }

  function handleToggleResources () {
    setShowResources(prevState => !prevState)
  }

  function handleToggleSites () {
    setShowSites(prevState => !prevState)
  }

  function handleTicketHoverStart (ticketId) {
    setHoverTicketId(ticketId)
  }

  function handleTicketHoverEnd () {
    setHoverTicketId(null)
  }

  function handleUserHoverStart (userId) {
    setHoverUserId(userId)
  }

  function handleUserHoverEnd () {
    setHoverUserId(null)
  }

  function handleTicketPanelChange (panelToToggle) {
    setQueryParams(previousParams => {
      return ({
        openTicketPanel: previousParams.openTicketPanel === panelToToggle ? 'closed' : panelToToggle
      })
    })
  }

  function handleLocationDetailChange (latLng) {
    setQueryParams({ latitude: latLng?.latitude, longitude: latLng?.longitude })
  }

  function handleSearchClear () {
    setQueryParams({}, 'replace')
  }

  return (
    <NormalLayoutContainer fullWidth>
      <div className='row'>
        <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
          <DispatchTicketSearchForm
            onSubmit={handleSearchSubmit}
            ticketTypes={pageData?.ticketTypes}
            resourceTypes={pageData?.resourceTypes}
            drivers={users}
            initialValues={{
              date: searchFields.date,
              status: searchFields.status,
              address: searchFields.address,
              ticketTypeId: searchFields.ticketTypeId,
              resourceTypeId: searchFields.resourceTypeId,
              driverId: searchFields.driverId
            }}
            handleClear={handleSearchClear}
          />
        </div>
        {isLoadingPageData || isLoadingTickets || !queryParams.date
          ? <Spinner isFetching />
          : (
            <>
              <div className='col-xs-12 col-sm-12 col-md-5 col-lg-3 no-margin-bottom overflow-hidden'>
                <DispatchTicketsList
                  users={users}
                  driverSortPreferences={pageData.driverSortPreferences}
                  onSaveDriverSortPreferences={handleSaveDriverSortPreferences}
                  unassignedTicketsSortPreference={pageData.unassignedTicketsSortPreferences || undefined}
                  onSaveUnassignedTicketsSortPreference={handleSaveUnassignedTicketsSortPreferences}
                  tickets={tickets}
                  onUpdateTicketFlag={updateTicketFlagged}
                  dispatchDate={queryParams.date}
                  onAssignTicketToUser={assignTicketToUser}
                  onReorderTickets={reorderTickets}
                  onTicketHoverStart={handleTicketHoverStart}
                  onTicketHoverEnd={handleTicketHoverEnd}
                  onUserHoverStart={handleUserHoverStart}
                  onUserHoverEnd={handleUserHoverEnd}
                  locationDetailTickets={locationDetailTickets}
                  onLocationDetailChange={handleLocationDetailChange}
                  onTicketPanelChange={handleTicketPanelChange}
                  ticketPanelOpen={queryParams.openTicketPanel}
                  refetchTickets={refetchTickets}
                />
              </div>

              <div className='hidden-xs hidden-sm col-md-7 col-lg-9'>
                <DispatchMap
                  tickets={tickets}
                  users={users}
                  resources={resources}
                  showResources={showResources}
                  onToggleResources={handleToggleResources}
                  sites={sitesData?.sites}
                  showSites={showSites}
                  onToggleSites={handleToggleSites}
                  hoverTicketId={hoverTicketId}
                  hoverUserId={hoverUserId}
                  onLocationDetailChange={handleLocationDetailChange}
                />
                <DispatchInfoBar
                  date={queryParams.date}
                  tickets={tickets}
                  lastRefreshed={ticketsLastUpdatedAt}
                />
              </div>
            </>
            )}
      </div>
    </NormalLayoutContainer>
  )
}

/**
 * Creates data that is appropriate for replacing the cached data for react-queries paginated query cache
 * @param oldData - old data from calling queryClient.setQueryData(oldData => oldData)
 * @param propertyName - property in the react-query cache that should be updated i.e. page.ticketsSearch, users
 * @param newData - new data we need to either add to the old data or update the old data with
 * @return results that can be used for react query queryClient.setQueryData for paginated queries
 */
function upsertToCachedPageData (oldData, propertyName, newData) {
  const updatedData = {
    ...(oldData ?? {}),
    pages: (oldData?.pages ?? []).map((page, index) => (upsertToCachedEdgeData(page, propertyName, newData, index === 0)))
  }

  // In case there are no pages, then we should at least add the new data
  if (updatedData.pages.length === 0) {
    updatedData.pages[0] = upsertToCachedEdgeData(oldData, propertyName, newData, true)
  }

  return updatedData
}

/**
 * Creates data that is appropriate for replacing the cached data for react-queries paginated "edge" query cache
 * @param oldData - old data from calling queryClient.setQueryData(oldData => oldData)
 * @param propertyName - property in the react-query cache that should be updated i.e. page.ticketsSearch, users
 * @param newData - new data we need to either add to the old data or update the old data with
 * @param shouldCreate - should "newData" be added to the edges if it does not exist
 * @return results that can be used for react query queryClient.setQueryData for paginated queries
 */
function upsertToCachedEdgeData (oldData, propertyName, newData, shouldCreate) {
  const updatedData = {
    ...(oldData ?? {}),
    [propertyName]: {
      ...(oldData[propertyName] ?? {}),
      edges: (oldData[propertyName]?.edges ?? []).map(edge => {
        const matchedNewData = newData[edge.node.id]
        if (!matchedNewData) return edge

        return {
          ...edge,
          node: { ...edge.node, ...matchedNewData }
        }
      })
    }
  }

  if (!shouldCreate) return updatedData

  const allData = (oldData?.[propertyName]?.edges ?? []).reduce((acc, edge) => {
    return {
      ...acc,
      [edge.node.id]: edge
    }
  }, {})
  const newDataIds = Object.keys(newData).filter(newDataId => allData[newDataId] === undefined)
  if (newDataIds.length > 0) {
    set(updatedData, [propertyName, 'edges'], [
      ...(updatedData?.[propertyName]?.edges ?? []),
      ...newDataIds.map(newDataId => ({ node: newData[newDataId] }))
    ])
  }

  return updatedData
}

/**
 * Creates data that is appropriate for replacing the cached data for react-queries paginated query cache
 * @param {Object} oldData - old data from calling queryClient.setQueryData(oldData => oldData)
 * @param {string} propertyName - property in the react-query cache that should be updated i.e. page.ticketsSearch, users
 * @param {Object} removeData - data that needs removed from the "oldData"
 * @return {Object} results that can be used for react query queryClient.setQueryData for paginated queries
 */
function removeFromCachedPageData (oldData, propertyName, removeData) {
  return {
    ...(oldData ?? {}),
    pages: (oldData?.pages ?? []).map(page => removeFromCachedEdgeData(page, propertyName, removeData))
  }
}

/**
 * Creates data that is appropriate for replacing the cached data for react-queries paginated "edge" query cache
 * @param {Object} oldData - old data from calling queryClient.setQueryData(oldData => oldData)
 * @param {string} propertyName - property in the react-query cache that should be updated i.e. page.ticketsSearch, users
 * @param {Object} removeData - data that needs removed from the "oldData"
 * @return {Object} results that can be used for react query queryClient.setQueryData for paginated queries
 */
function removeFromCachedEdgeData (oldData, propertyName, removeData) {
  return {
    ...(oldData ?? {}),
    [propertyName]: {
      ...(oldData[propertyName] ?? {}),
      edges: (oldData[propertyName]?.edges ?? []).filter(edge => removeData[edge.node.id] === undefined)
    }
  }
}

/**
 * Tickets coming from Pusher/Thumbster don't have our GraphQL serialization i.e. IDs should be strings, etc...
 * @param {Object} ticket
 * @return {Object}
 */
function normalizeTicket (ticket) {
  return {
    id: ticket.id.toString(),
    date: ticket.date,
    status: ticket.status,
    flagged: ticket.flagged,
    ticketTypeId: ticket.ticket_type_id?.toString(),
    resourceTypeId: ticket.resource_type_id?.toString(),
    driverId: ticket.driver_id?.toString()
  }
}
