import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import moment from 'moment'
import styles from './Jobs.module.scss'
import SMSEnableButton from '../../../../../../components/sms-enable-button'

export default function JobDisplay ({
  job,
  handleEdit,
  updateSMS,
  isSMSLoading
}) {
  const [isSMSEnabledPrimary, setIsSMSEnabledPrimary] = useState(job.smsActive && job.smsPrimaryContactOptOut === null)
  const [isSMSEnabledSecondary, setIsSMSEnabledSecondary] = useState(job.smsActive && job.smsSecondaryContactOptOut === null)

  async function handlePrimarySMS (isEnabledAtTimeOfClick) {
    await updateSMS({
      latitude: parseFloat(job.latitude),
      longitude: parseFloat(job.longitude),
      smsPrimaryContactActive: !isEnabledAtTimeOfClick,
      id: job.id
    })
    setIsSMSEnabledPrimary(!isEnabledAtTimeOfClick)
  }

  async function handleSecondarySMS (isEnabledAtTimeOfClick) {
    await updateSMS({
      latitude: parseFloat(job.latitude),
      longitude: parseFloat(job.longitude),
      smsSecondaryContactActive: !isEnabledAtTimeOfClick,
      id: job.id
    })
    setIsSMSEnabledSecondary(!isEnabledAtTimeOfClick)
  }

  return (
    <div className='row'>
      <div className='dis-list-group'>

        <div className='dis-list-item dis-list-item-gray'>
          <div className={cn(styles.listSection, 'dis-list-group-header')}>
            <h5>Job Details</h5>
            <button
              className='dis-btn dis-btn-lg dis-btn-primary-dk'
              onClick={handleEdit}
              disabled={job?.status !== 'open'}>
              <span className='pull-left'>Edit Job</span>
              <i className='material-icons pull-right space-left'>mode_edit</i>
            </button>
          </div>
        </div>

        <div className={cn(styles.listSection, styles.listTop, 'dis-list-item dis-list-item-thick')}>
          <div>
            <p className={cn('dis-list-item-text', styles.listHeader)}>Account Name</p>
            <p className={cn(styles.listText, 'dis-list-item-text')}>
              <Link to={`/hauler/clients/${job?.client?.id}`}>{job?.client?.name}</Link>
            </p>
          </div>
          <div className={cn(styles.listRightWrapper)}>
            <i className={cn('material-icons')}>business</i>
          </div>
        </div>

        <div className={cn(styles.listSection, styles.listTop, 'dis-list-item dis-list-item-thick')}>
          <div>
            <p className={cn('dis-list-item-text', styles.listHeader)}>
              Primary Contact Info
            </p>
            <p className={cn('dis-list-item-text', styles.listText)}>
              {(() => {
                if (!job?.firstName && !job?.lastName) {
                  return 'None'
                } else {
                  let primaryName = job?.firstName ? `${job?.firstName} ` : ''
                  primaryName += job?.lastName ? `${job?.lastName}` : ''
                  return primaryName
                }
              })()}
            </p>
            <p className={cn(styles.listText, 'dis-list-item-text')}>
              {(() => {
                if (job?.prettyPrimaryPhone) {
                  return `${job?.prettyPrimaryPhone}`
                }
                return 'None'
              })()}
            </p>
            <p className={cn(styles.listText, 'dis-list-item-text')}>
              {(() => {
                if (job?.email) {
                  return <a href={`mailto:${job?.email}`}>{job?.email}</a>
                }
                return 'None'
              })()}
            </p>
          </div>
          <div className={cn(styles.listRightWrapper)}>
            <i className={cn('material-icons')}>person</i>
            { job.prettyPrimaryPhone && (
              <SMSEnableButton
                smsEnabled={isSMSEnabledPrimary}
                handleUpdate={handlePrimarySMS}
                isLoading={isSMSLoading}
                step='CONTACT'
                isParentEnabled={job.client.smsActive && job.smsActive}
                levelName={job.customId}
              />
            )}
          </div>
        </div>

        <div className={cn(styles.listSection, styles.listTop, 'dis-list-item dis-list-item-thick')}>
          <div>
            <p className={cn('dis-list-item-text', styles.listHeader)}>
              Secondary Contact Info
            </p>
            <p className={cn(styles.listText, 'dis-list-item-text')}>
              {(() => {
                if (!job?.secondaryFirstName && !job?.secondaryLastName) {
                  return 'None'
                } else {
                  let secondaryName = job?.secondaryFirstName ? `${job?.secondaryFirstName} ` : ''
                  secondaryName += job?.secondaryLastName ? `${job?.secondaryLastName}` : ''
                  return secondaryName
                }
              })()}
            </p>
            <p className={cn(styles.listText, 'dis-list-item-text')}>
              {(() => {
                if (job?.prettySecondaryPhone) {
                  return `${job?.prettySecondaryPhone}`
                }
                return 'No Phone Number'
              })()}
            </p>
            <p className={cn(styles.listText, 'dis-list-item-text')}>
              {(() => {
                if (job?.secondaryEmail) {
                  return <a href={`mailto:${job?.secondaryEmail}`}>{job?.secondaryEmail}</a>
                }
                return 'No Email'
              })()}
            </p>
          </div>
          <div className={cn(styles.listRightWrapper)}>
            <i className='material-icons'>person</i>
            { job.prettySecondaryPhone && (
              <SMSEnableButton
                smsEnabled={isSMSEnabledSecondary}
                handleUpdate={handleSecondarySMS}
                isLoading={isSMSLoading}
                step='CONTACT'
                isParentEnabled={job.smsActive}
                levelName={job.customId}
              />
            )}
          </div>
        </div>

        <div className={cn(styles.listSection, styles.listTop, 'dis-list-item dis-list-item-thick')}>
          <div>
            <p className={cn('dis-list-item-text', styles.listHeader)}>
              Created At
            </p>
            <p className={cn(styles.listText, 'dis-list-item-text')}>
              {job?.createdAt ? moment(job?.createdAt).format('dddd, MMMM Do YYYY, h:mm:ss a') : 'None'}
            </p>
          </div>
          <div className={cn(styles.listRightWrapper)}>
            <i className='material-icons'>access_time</i>
          </div>
        </div>

        <div className={cn(styles.listSection, styles.listTop, 'dis-list-item dis-list-item-thick')}>
          <div>
            <p className={cn('dis-list-item-text', styles.listHeader)}>
              Job Notes
            </p>
            <p className={cn(styles.listText, 'dis-list-item-text')}>
              {job?.notes || 'No Job Notes'}
            </p>
          </div>
          <div className={cn(styles.listRightWrapper)}>
            <i className='material-icons'>speaker_notes</i>
          </div>
        </div>
        <div className={cn(styles.listSection, styles.listTop, 'dis-list-item dis-list-item-thick')}>
          <div>
            <p className={cn('dis-list-item-text', styles.listHeader)}>
              {'Admin Notes (Visible Only By Admins)'}
            </p>
            <p className={cn(styles.listText, 'dis-list-item-text')}>
              {job?.internalAdminNotes || 'No admin Notes'}
            </p>
          </div>
          <div className={cn(styles.listRightWrapper)}>
            <i className='material-icons'>speaker_notes</i>
          </div>
        </div>
      </div>
    </div>
  )
}

JobDisplay.propTypes = {
  updateSMS: PropTypes.func.isRequired,
  isSMSLoading: PropTypes.bool.isRequired,
  handleEdit: PropTypes.func.isRequired,
  job: PropTypes.shape({
    status: PropTypes.string,
    id: PropTypes.string,
    customId: PropTypes.number,
    client: PropTypes.any,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    prettyPrimaryPhone: PropTypes.string,
    email: PropTypes.string,
    secondaryFirstName: PropTypes.string,
    secondaryLastName: PropTypes.string,
    prettySecondaryPhone: PropTypes.string,
    secondaryEmail: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    createdAt: PropTypes.string,
    notes: PropTypes.string,
    internalAdminNotes: PropTypes.string,
    smsActive: PropTypes.bool,
    smsPrimaryContactOptOut: PropTypes.bool,
    smsSecondaryContactOptOut: PropTypes.bool
  }).isRequired
}
