import { intercomConnection } from '../../intercomConnection'
import { loader } from 'graphql.macro'

export default async function updateJob ({ dataLoaders, variables }) {
  const updateJobSchema = loader('./updateJob.graphql')
  let jobFields = {
    latitude: variables.latitude,
    longitude: variables.longitude,
    addressline1: variables.displayAddress || variables.addressline1,
    city: variables.city,
    state: variables.state,
    zip: variables.zip,
    country: variables.country,
    notes: variables.notes,
    internalAdminNotes: variables.internalAdminNotes,
    firstName: variables.firstName,
    lastName: variables.lastName,
    email: variables.email,
    primaryPhone: variables.prettyPrimaryPhone,
    placeId: variables.place_id,
    secondaryFirstName: variables.secondaryFirstName,
    secondaryLastName: variables.secondaryLastName,
    secondaryEmail: variables.secondaryEmail,
    secondaryPhone: variables.prettySecondaryPhone,
    addressDetails: variables.addressDetails
  }

  // sms is its own thing at the moment so only send if it's the focus of the update
  if (variables.smsActive != null) {
    jobFields = {
      latitude: variables.latitude,
      longitude: variables.longitude,
      smsActive: variables.smsActive
    }
  }

  if (variables.smsPrimaryContactActive != null) {
    jobFields = {
      latitude: variables.latitude,
      longitude: variables.longitude,
      smsPrimaryContactActive: variables.smsPrimaryContactActive
    }
  }

  if (variables.smsSecondaryContactActive != null) {
    jobFields = {
      latitude: variables.latitude,
      longitude: variables.longitude,
      smsSecondaryContactActive: variables.smsSecondaryContactActive
    }
  }

  const response = await dataLoaders.dispatcher.graphQLClient.request(
    updateJobSchema,
    { input: { jobFields, jobId: variables.id } }
  )
  intercomConnection.trackEvent('job_updated')

  return response.updateJob
}
