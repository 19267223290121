import React from 'react'
import PropTypes from 'prop-types'
import { Form, Field } from 'react-final-form'
import { TextInput } from '../TextInput'
import LatLngSearchGoogleMap from '../maps/lat-lng-map/LatLngSearchGoogleMap'
import AutocompleteGoogleMap from '../../pages/shared/locationPickers/AutocompleteGoogleMap'
import normalizePhone from '../../pages/shared/normalizers/normalizePhone'
import normalizePositiveNumber from '../../pages/shared/normalizers/normalizePositiveNumber'
import { prettyAddress } from '../../utilities/locationUtilities'
import styles from './index.module.scss'
import { PrimaryPhoneValidator } from '../../utilities/commonValidators'
import { TextareaInput } from '../TextareaInput'

export default function NewJobForm ({ onSubmit, defaultCoordinates = {}, disabled = false, initialValues = {} }) {
  function handleLocationChange (form, newLocation) {
    form.batch(() => {
      form.change('latitude', newLocation.latitude)
      form.change('longitude', newLocation.longitude)
      form.change('placeId', newLocation.place_id)
      form.change('addressline1', newLocation.addressline1)
      form.change('city', newLocation.city)
      form.change('state', newLocation.state)
      form.change('zip', newLocation.zip)
      form.change('country', newLocation.country)
      form.change('displayAddress', newLocation.displayAddress)
    })
  }

  function toggleShowSecondary (form, currentShowSecondary) {
    form.change('showSecondary', !currentShowSecondary)
  }

  return (
    <div className='row panel panel-default'>
      <div className='container-fluid push-down-super-small'>
        <div className='col-xs-12'>
          <Form onSubmit={onSubmit} validate={validate} initialValues={{
            ...initialValues,
            navToTicket: false,
            mapSearchType: 'address',
            showSecondary: false,
            notes: null,
            internalAdminNotes: null
          }}>
            {({ handleSubmit, values, form, pristine, invalid }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  name='firstName'
                  label='Primary Contact First Name'
                  component={TextInput}
                  maxLength='20'
                />

                <Field
                  name='lastName'
                  label='Primary Contact Last Name'
                  component={TextInput}
                  maxLength='20'
                />
                <div className='form-row'>
                  <Field
                    name='prettyPrimaryPhone'
                    label='Primary Contact Primary Phone'
                    component={TextInput}
                    format={normalizePhone}
                    className='col-md-8 phone-input'
                  />
                  <Field
                    name='primaryPhoneExtension'
                    label='Ext.'
                    component={TextInput}
                    format={normalizePositiveNumber}
                    type='number'
                    className='col-md-4 phone-ext-input'
                  />
                </div>
                <Field
                  name='email'
                  label='Primary Contact Email'
                  component={TextInput}
                  maxLength='64'
                />

                {values.showSecondary && (<>
                  <Field
                    name='secondaryFirstName'
                    label='Secondary Contact First Name'
                    component={TextInput}
                    maxLength='20'
                  />

                  <Field
                    name='secondaryLastName'
                    label='Secondary Contact Last Name'
                    component={TextInput}
                    maxLength='20'
                  />
                  <Field
                    name='prettySecondaryPhone'
                    label='Secondary Contact Primary Phone'
                    component={TextInput}
                    format={normalizePhone}
                  />

                  <Field
                    name='secondaryEmail'
                    label='Secondary Contact Email'
                    component={TextInput}
                    maxLength='40'
                  />
                </>)}

                <button
                  className='btn btn-small btn-link pull-right'
                  type='button'
                  onClick={() => toggleShowSecondary(form, values.showSecondary)}>
                  <i className='material-icons pull-left'>
                    {values.showSecondary ? 'remove' : 'add'}
                  </i>
                  {values.showSecondary ? 'Remove Secondary Contact' : 'Add Secondary Contact'}
                </button>

                <br /><br /><br />

                <Field
                  component={TextareaInput}
                  label='Job Notes'
                  maxLength='30000'
                  multiLine
                  name='notes'
                  placeholder='(Job notes are displayed on every ticket created for a job. Drivers will see these.)'
                  rows={4}
                />

                <br /><br /><br />

                <Field
                  component={TextareaInput}
                  label='Admin Notes'
                  maxLength='30000'
                  multiLine
                  name='internalAdminNotes'
                  placeholder='(Admin notes are displayed on every ticket created for a job. Only Admins will see these.)'
                  rows={4}
                />

                <br /><br /><br />

                <label htmlFor='mapSearchTypeAddress' className='mb-6'>
                  <Field id='mapSearchTypeAddress' name='mapSearchType' value='address' type='radio' component='input' />
                  <span>Address</span>
                </label>
                <label htmlFor='mapSearchTypeLatLng' className='ml-12 mb-6'>
                  <Field id='mapSearchTypeLatLng' name='mapSearchType' value='latLng' type='radio' component='input' />
                  <span>
                    Custom Location
                    <span className='text-lg ml-2'>
                      (Enter Lat/Long or drag and drop pin)
                    </span>
                  </span>
                </label>
                {values.mapSearchType === 'latLng' && (<>
                  <div className='-mt-4 mb-8 italic text-gray text-lg'>
                    Please note, Dispatcher always uses the latitude and longitude to plot the map marker and for
                    navigation but will use the Display Address field below the map to display on the ticket details
                    and mobile app.
                  </div>
                  <LatLngSearchGoogleMap
                    mapClass='static-google-map-jobs'
                    mapId='newJobFormMapLatLng'
                    latitude={values.latitude}
                    longitude={values.longitude}
                    address={values.latitude && values.longitude ? prettyAddress(values) : ''}
                    onLocationChange={newLocation => handleLocationChange(form, newLocation)}
                    hauler={defaultCoordinates}
                  />
                </>)}
                {values.mapSearchType === 'address' && (<>
                  <div className='-mt-4 mb-8 italic text-gray text-lg'>
                    Enter the address below. Use the Custom Location option to drag the pin to a nearby point or enter
                    a specific latitude and longitude.
                  </div>
                  <AutocompleteGoogleMap
                    place_id={values.placeId}
                    latitude={values.latitude}
                    longitude={values.longitude}
                    initialValue={values.latitude && values.longitude ? prettyAddress(values) : ''}
                    mapClass='static-google-map-jobs'
                    placeholder='Address for this Job'
                    externalInput={true}
                    onLocationChange={newLocation => handleLocationChange(form, newLocation)}
                    draggable={true}
                    gestureHandling='cooperative'
                    hauler={defaultCoordinates}
                  />
                </>)}

                {/* Hidden fields still need to be here for react-final-form to consider them in form state i.e. pristine */}
                <Field name='latitude'>{() => null}</Field>
                <Field name='longitude'>{() => null}</Field>
                <Field name='placeId'>{() => null}</Field>
                <Field name='addressline1'>{() => null}</Field>
                <Field name='city'>{() => null}</Field>
                <Field name='state'>{() => null}</Field>
                <Field name='zip'>{() => null}</Field>
                <Field name='country'>{() => null}</Field>

                <br /><br />

                {values.mapSearchType === 'latLng' && (
                  <div className={styles.displayAddressContainer}>
                    <Field
                      className={styles.displayAddressInput}
                      name='displayAddress'
                      label='Display Address'
                      component={TextInput}
                    />
                    <div className={styles.addressFields}>
                      {values.latitude && values.longitude
                        ? `${values.city || 'unknown'} ${values.state || 'unknown'}, ${values.zip || 'unknown'}`
                        : ''}
                    </div>
                  </div>
                )}

                <Field
                  name='addressDetails'
                  label='Address Details (suite number, etc.)'
                  component={TextInput}
                  maxLength='30'
                />

                <button
                  type='button'
                  className='btn btn-primary push-down-very-small pull-right'
                  disabled={pristine || invalid || disabled}
                  onClick={() => {
                    form.change('navToTicket', true)
                    form.submit()
                  }}>
                  <span className='pull-left'>Create Job and Enter Ticket</span>
                  <i className='material-icons pull-right thumbster-button-icon'>add_circle</i>
                </button>

                <button
                  type='button'
                  className='btn btn-default push-down-very-small pull-right space-right'
                  disabled={pristine || invalid || disabled}
                  onClick={() => {
                    form.change('navToTicket', false)
                    form.submit()
                  }}>
                  Create Job
                </button>
              </form>
            )}
          </Form>
        </div>
      </div>
      <br />
    </div>
  )
}

NewJobForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  defaultCoordinates: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number
  }),
  disabled: PropTypes.bool
}

function validate (values) {
  const errors = {}

  if (values.prettyPrimaryPhone && !PrimaryPhoneValidator(values.prettyPrimaryPhone)) {
    errors.prettyPrimaryPhone = 'Invalid Phone Number'
  }

  if (!values.latitude) {
    errors.latitude = 'Required'
  }
  if (!values.longitude) {
    errors.longitude = 'Required'
  }

  const noAddressLine1 = !values.addressline1 || (values.addressline1 && !values?.addressline1?.trim().length)
  const noDisplayAddress = !values.displayAddress || (values.displayAddress && !values?.displayAddress?.trim().length)

  if (noAddressLine1 && noDisplayAddress) {
    errors.displayAddress = 'Required'
  }

  return errors
}
