import React from 'react'
import PropTypes from 'prop-types'
import { Field, Form } from 'react-final-form'
import { TextInput } from '../TextInput'
import AutocompleteGoogleMap from '../../pages/shared/locationPickers/AutocompleteGoogleMap'
import LatLngSearchGoogleMap from '../../components/maps/lat-lng-map/LatLngSearchGoogleMap'
import normalizePhone from '../../pages/shared/normalizers/normalizePhone'
import { PrimaryPhoneValidator } from '../../utilities/commonValidators'
import { prettyAddress } from '../../utilities/locationUtilities'
import styles from './index.module.scss'
import { TextareaInput } from '../TextareaInput'

export default function JobEditForm ({ onSubmit, initialValues, disabled = false, defaultCoordinates }) {
  function handleLocationChange (form, newLocation) {
    form.batch(() => {
      form.change('latitude', newLocation.latitude)
      form.change('longitude', newLocation.longitude)
      form.change('placeId', newLocation.place_id)
      form.change('addressline1', newLocation.addressline1)
      form.change('city', newLocation.city)
      form.change('state', newLocation.state)
      form.change('zip', newLocation.zip)
      form.change('country', newLocation.country)
      form.change('displayAddress', newLocation.displayAddress)
    })
  }

  return (
    <div className='row panel panel-default'>
      <div className='container-fluid push-down-super-small'>
        <div className='col-xs-12'>
          <Form onSubmit={onSubmit} validate={validate} initialValues={{ ...initialValues, mapSearchType: 'address', displayAddress: initialValues.addressline1 }}>
            {({ handleSubmit, values, form, pristine, invalid }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  name='firstName'
                  label='Contact First Name'
                  component={TextInput}
                  maxLength='20'
                />
                <Field
                  name='lastName'
                  label='Contact Last Name'
                  component={TextInput}
                  maxLength='20'
                />
                <Field
                  name='email'
                  label='Contact Email'
                  component={TextInput}
                  maxLength='64'
                />
                <Field
                  name='prettyPrimaryPhone'
                  label='Contact Primary Phone'
                  component={TextInput}
                  format={normalizePhone}
                />
                <Field
                  name='secondaryFirstName'
                  label='Secondary Contact First Name'
                  component={TextInput}
                  maxLength='20'
                />
                <Field
                  name='secondaryLastName'
                  label='Secondary Contact Last Name'
                  component={TextInput}
                  maxLength='20'
                />
                <Field
                  name='prettySecondaryPhone'
                  label='Secondary Contact Primary Phone'
                  component={TextInput}
                  format={normalizePhone}
                />
                <Field
                  name='secondaryEmail'
                  label='Secondary Contact Email'
                  component={TextInput}
                  maxLength='64'
                />
                <br /><br />
                <Field
                  component={TextareaInput}
                  label='Job Notes'
                  maxLength='30000'
                  multiLine
                  name='notes'
                  placeholder='(Job notes are displayed on every ticket created for a job. Drivers will see these.)'
                  rows={4}
                />
                <Field
                  component={TextareaInput}
                  label='Admin Notes'
                  maxLength='30000'
                  multiLine
                  name='internalAdminNotes'
                  placeholder='(Admin notes are displayed on every ticket created for a job. Only Admins will see these.)'
                  rows={4}
                />

                {/* Hidden fields still need to be here for react-final-form to consider them in form state i.e. pristine */}
                <Field name='latitude'>{() => null}</Field>
                <Field name='longitude'>{() => null}</Field>
                <Field name='placeId'>{() => null}</Field>
                <Field name='addressline1'>{() => null}</Field>
                <Field name='city'>{() => null}</Field>
                <Field name='state'>{() => null}</Field>
                <Field name='zip'>{() => null}</Field>
                <Field name='country'>{() => null}</Field>

                <br /><br />

                <label htmlFor='mapSearchTypeAddress' className='mb-6'>
                  <Field id='mapSearchTypeAddress' name='mapSearchType' value='address' type='radio' component='input' />
                  <span>Address</span>
                </label>
                <label htmlFor='mapSearchTypeLatLng' className='ml-12 mb-6'>
                  <Field id='mapSearchTypeLatLng' name='mapSearchType' value='latLng' type='radio' component='input' />
                  <span>
                    Custom Location
                    <span className='text-lg ml-2'>
                      (Enter Lat/Long or drag and drop pin)
                    </span>
                  </span>
                </label>
                {values.mapSearchType === 'latLng' && (
                  <>
                    <div className='-mt-4 mb-8 italic text-gray text-lg'>
                      Please note, Dispatcher always uses the latitude and longitude to plot the map marker and for
                      navigation but will use the Display Address field below the map to display on the ticket details and
                      mobile app.
                    </div>
                    <LatLngSearchGoogleMap
                      mapClass='static-google-map-jobs'
                      latitude={values.latitude}
                      longitude={values.longitude}
                      address={prettyAddress(values)}
                      mapId='editJobFormMapLatLng'
                      onLocationChange={newLocation => handleLocationChange(form, newLocation)}
                      hauler={defaultCoordinates}
                    />
                  </>
                )}
                {values.mapSearchType === 'address' &&
                  <>
                    <div className='-mt-4 mb-8 italic text-gray text-lg'>
                      Enter the address below. Use the Custom Location option to drag the pin to a nearby point or enter a
                      specific latitude and longitude.
                    </div>
                    <AutocompleteGoogleMap
                      mapClass='static-google-map-jobs'
                      externalInput={true}
                      place_id={values.placeId}
                      latitude={values.latitude}
                      longitude={values.longitude}
                      initialValue={prettyAddress(values)}
                      onLocationChange={newLocation => handleLocationChange(form, newLocation)}
                      draggable={true}
                      gestureHandling='cooperative'
                    />
                  </>}

                <br /><br />

                {values.mapSearchType === 'latLng' && (
                  <div className={styles.displayAddressContainer}>
                    <Field
                      className={styles.displayAddressInput}
                      name='displayAddress'
                      label='Display Address'
                      component={TextInput}
                    />
                    <div className={styles.addressFields}>
                      {values.latitude && values.longitude &&
                        `${values.city || 'unknown'} ${values.state || 'unknown'}, ${values.zip || 'unknown'}`}
                    </div>
                  </div>
                )}

                <Field
                  name='addressDetails'
                  label='Address Details (suite number, etc.)'
                  component={TextInput}
                  maxLength='30'
                />

                <br />

                <button
                  type='submit'
                  className='btn btn-primary push-down-very-small pull-right'
                  disabled={pristine || invalid || disabled}>
                  <span className='pull-left'>Save Changes</span>
                  <i className='material-icons pull-right thumbster-button-icon'>check</i>
                </button>

              </form>
            )}
          </Form>
        </div>
      </div>
      <br />
    </div>
  )
}

JobEditForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  defaultCoordinates: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number
  })
}

function validate (values) {
  const errors = {}

  if (values.prettyPrimaryPhone && !PrimaryPhoneValidator(values.prettyPrimaryPhone)) {
    errors.prettyPrimaryPhone = 'Invalid Phone Number'
  }

  if (!values.latitude) {
    errors.latitude = 'Required'
  }
  if (!values.longitude) {
    errors.longitude = 'Required'
  }

  const noAddressLine1 = !values.addressline1 || (values.addressline1 && !values?.addressline1?.trim().length)
  const noDisplayAddress = !values.displayAddress || (values.displayAddress && !values?.displayAddress?.trim().length)

  if (noAddressLine1 && noDisplayAddress) {
    errors.displayAddress = 'Required'
  }

  return errors
}
