import React from 'react'
import PropTypes from 'prop-types'
import DispatchTicketDisplay from './dispatch-ticket-display'

export const DispatchUserSortableTicketItem = ({
  ticket,
  onTicketHoverStart,
  onTicketHoverEnd,
  onAssignTicketToUser,
  onChangeSite,
  onUpdateTicketFlag,
  dragHandleProps,
  reordering
}) => {
  function handleMouseEnter () {
    if (reordering) return
    onTicketHoverStart(ticket.id)
  }

  function handleMouseLeave () {
    if (reordering) return
    onTicketHoverEnd()
  }

  return (
    <div
      className='row py-2 px-14 border border-gray-light'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      <DispatchTicketDisplay
        onUpdateTicketFlag={onUpdateTicketFlag}
        onAssignTicketToUser={onAssignTicketToUser}
        onChangeSite={onChangeSite}
        ticket={ticket}
        dragHandleProps={dragHandleProps}
        isDraggable={true}
      />
    </div>
  )
}

DispatchUserSortableTicketItem.propTypes = {
  ticket: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired,
  onUpdateTicketFlag: PropTypes.func.isRequired,
  onAssignTicketToUser: PropTypes.func.isRequired,
  onChangeSite: PropTypes.func.isRequired,
  reordering: PropTypes.bool.isRequired,
  onTicketHoverStart: PropTypes.func.isRequired,
  onTicketHoverEnd: PropTypes.func.isRequired,
  dragHandleProps: PropTypes.any
}
