import React from 'react'
import PropTypes from 'prop-types'
import { generatePath, Link } from 'react-router-dom'
import Tooltip from 'react-bootstrap/lib/Tooltip'
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger'
import NoResults from '../../pages/shared/NoResults'
import CursorPagination from '../cursor-pagination'
import JobStatusLabel from '../JobStatusLabel'
import { ROUTES } from '../../routes'

function TableHeader ({ label, columnName, onSortChange, sort }) {
  const currentlySorted = sort.column === columnName

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div className='cursor-pointer' onClick={() => onSortChange(columnName)}>
      {label}
      {currentlySorted && (
        <span className='pull-right'>{ sort.direction === 'asc' ? <>&uarr;</> : <>&darr;</> }</span>
      )}
    </div>
  )
}

TableHeader.propTypes = {
  label: PropTypes.string.isRequired,
  columnName: PropTypes.string.isRequired,
  sort: PropTypes.shape({
    column: PropTypes.oneOf(['customId', 'status', 'address',
      'clientName', 'openTicket', 'closedTicket', '']).isRequired,
    direction: PropTypes.oneOf(['asc', 'desc']).isRequired
  }).isRequired,
  onSortChange: PropTypes.func.isRequired
}

export default function JobsTable ({
  onPageRequest,
  sort,
  onSortChange,
  jobs,
  fakeCount
}) {
  return (
    <div className='row'>
      <div className='col-xs-12 table-responsive'>
        <table className='table table-striped'>
          <thead>
            <tr>
              <th width='5%'>
                <TableHeader
                  label='ID'
                  columnName='customId'
                  sort={sort}
                  onSortChange={onSortChange}
                />
              </th>
              <th width='3%'>
                <TableHeader
                  label='Status'
                  columnName='status'
                  sort={sort}
                  onSortChange={onSortChange}
                />
              </th>
              <th width='3%'>Job Notes</th>
              <th width='3%'>Admin Notes</th>
              <th width='35%'>
                <TableHeader
                  label='Address (Address Details)'
                  columnName='address'
                  sort={sort}
                  onSortChange={onSortChange}
                />
              </th>
              <th width='15%'>
                <TableHeader
                  label='Account'
                  columnName='clientName'
                  sort={sort}
                  onSortChange={onSortChange}
                />
              </th>
              <th width='10%'>
                <TableHeader
                  label='Open Tickets'
                  columnName='openTicket'
                  sort={sort}
                  onSortChange={onSortChange}
                />
              </th>
              <th width='10%'>
                <TableHeader
                  label='Closed Tickets'
                  columnName='closedTicket'
                  sort={sort}
                  onSortChange={onSortChange}
                />
              </th>
              <th width='15%'>Job Details</th>
            </tr>
          </thead>
          <tbody>
            {jobs.nodes.length > 0
              ? jobs.nodes.map(job => {
                const jobPath = `/hauler/jobs/${job.id}`
                const clientPath = `/hauler/clients/${job.client.id}`
                return (
                  <tr key={job.id}>
                    <td>
                      <div><Link to={jobPath}>{job.customId}</Link></div>
                    </td>
                    <td>
                      <JobStatusLabel status={job.status} linkPath={jobPath} />
                    </td>
                    <td>
                      {(() => {
                        if (job.notes) {
                          const tooltip = (
                            <Tooltip id='tooltip'>{job.notes}</Tooltip>
                          )
                          return (<OverlayTrigger placement='top' overlay={tooltip}>
                            <i className='material-icons'>speaker_notes</i>
                          </OverlayTrigger>)
                        }
                        return null
                      })()}
                    </td>
                    <td>
                      {(() => {
                        if (job.internalAdminNotes) {
                          const tooltip = (
                            <Tooltip id='tooltip'>{job?.internalAdminNotes}</Tooltip>
                          )
                          return (<OverlayTrigger placement='top' overlay={tooltip}>
                            <i className='material-icons'>speaker_notes</i>
                          </OverlayTrigger>)
                        }
                        return null
                      })()}
                    </td>
                    <td>
                      <Link
                        to={jobPath}>{job.addressline1} {job.city}, {job.state} {job.zip}
                        {job.addressDetails ? `(${job.addressDetails})` : null }</Link>
                    </td>
                    <td>
                      <Link to={clientPath}>{job.client.name}</Link>
                    </td>
                    <td>
                      {(() => {
                        if (job.activeTicketsCount > 0) {
                          return (<Link to={`/hauler/tickets/search?jobCustomId=${job.customId}&status=active`}>
                            {job.activeTicketsCount}
                          </Link>)
                        }
                        return 'None'
                      })()}
                    </td>
                    <td>
                      {(() => {
                        if (job.terminalTicketsCount) {
                          return (
                            <Link
                              to={`/hauler/tickets/search?jobCustomId=${job.customId}&status=terminal`}>{job.terminalTicketsCount}</Link>
                          )
                        }
                        return 'None'
                      })()}
                    </td>
                    <td>
                      <div className='btn-group'>
                        <Link to={jobPath} className='btn btn-sm btn-primary-dk'>
                          Details
                        </Link>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a className='btn btn-primary-dk btn-sm dropdown-toggle hidden-xs hidden-sm drop-down' data-toggle='dropdown'>
                          <span className='caret' />
                        </a>
                        <ul className='dropdown-menu'>
                          <li>
                            <Link to={generatePath(ROUTES.editJob, { id: job.id })}>
                              <span>Edit Job</span>
                              <i className='material-icons'>edit</i>
                            </Link>
                          </li>
                          {(() => {
                            if (job.status === 'open') {
                              return (<li>
                                <Link to={`/hauler/tickets/new?job_id=${job.id}`}>
                                  <span>New Ticket</span>
                                  <i className='material-icons'>add</i>
                                </Link>
                              </li>)
                            }
                            return null
                          })()}
                          {(() => {
                            if (job.status === 'open') {
                              return (<li>
                                <Link to={`/hauler/tickets/batch/create?job_id=${job.id}`}>
                                  <span>Many Tickets</span>
                                  <i className='material-icons'>add_circle_outline</i>
                                </Link>
                              </li>)
                            }
                            return null
                          })()}
                        </ul>
                      </div>
                    </td>
                  </tr>
                )
              })
              : <tr><td colSpan={12}><NoResults /></td></tr>}
          </tbody>
        </table>
        {jobs.nodes.length > 0 && <CursorPagination
          pageInfo={jobs.pageInfo}
          totalCount={jobs.totalCount}
          onPageRequest={onPageRequest}
          fakeCount={fakeCount}
                                  />}
      </div>
    </div>
  )
}

JobsTable.propTypes = {
  onPageRequest: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
  jobs: PropTypes.any.isRequired,
  sort: PropTypes.shape({
    column: PropTypes.oneOf(['customId', 'status', 'address',
      'clientName', 'openTicket', 'closedTicket', '']).isRequired,
    direction: PropTypes.oneOf(['asc', 'desc']).isRequired
  }).isRequired,
  fakeCount: PropTypes.shape({
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired
  })
}
