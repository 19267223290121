import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ObjectParam, StringParam, NumberParam, useQueryParams } from 'use-query-params'
import jobSearchQuery from '../../../../graphql/queries/jobsSearch'
import QUERY_KEYS from '../../../../graphql/queryKeys'
import { action } from '../../../../store/store'
import { REQUEST_ALL_CLIENTS_ACTION } from '../../clients/sagas/allClientsSaga'
import NormalLayoutContainer from '../../../shared/NormalLayoutContainer'
import PageTitle from '../../../../components/page-title'
import { Spinner } from '../../../shared/Spinner'
import JobsTableSearchForm from '../../../../components/jobs-search/JobsTableSearchForm'
import JobsTable from '../../../../components/jobs-search/JobsTable'
import useQuery from '../../../../hooks/useQuery'
import { captureErrorAndNotify } from '../../../../utilities/errorHandlers'

const pageSize = 25

export default function HaulerJobsSearchContainer () {
  const [fakeCount, setFakeCount] = useState(null)
  const { allClients, user } = useSelector(({ allClients, user: { user } }) => ({
    allClients,
    user
  }))
  const [queryParams, setQueryParams] = useQueryParams({
    sort: ObjectParam,
    pager: ObjectParam,
    customId: NumberParam,
    streetAddress: StringParam,
    city: StringParam,
    zipCode: StringParam,
    clientId: NumberParam,
    status: StringParam
  })

  const searchParams = useMemo(() => {
    return {
      clientId: queryParams.clientId,
      customId: queryParams.customId,
      streetAddress: queryParams.streetAddress,
      city: queryParams.city,
      zipCode: queryParams.zipCode,
      status: queryParams.status
    }
  }, [queryParams])

  const setQueryParamsReplace = useCallback((params) => {
    setQueryParams(params, 'replace')
  }, [setQueryParams])

  const { data, isFetching } = useQuery([
    QUERY_KEYS.jobs,
    user.haulerId,
    25,
    queryParams.sort,
    Object.fromEntries(Object.entries(searchParams).filter(([_, v]) => v)),
    queryParams.pager
  ],
  jobSearchQuery,
  {
    enabled: Boolean(queryParams.sort),
    onError (error) {
      captureErrorAndNotify(error, 'Error fetching jobs')
    }
  })

  function onPageRequest (direction, cursor) {
    setQueryParamsReplace({ ...queryParams, pager: { cursor, direction, pageSize } })
    if (!fakeCount) return
    if (direction === 'before') {
      setFakeCount(prevFakeCount => ({ start: prevFakeCount.start - pageSize, end: prevFakeCount.end - pageSize }))
    }
    if (direction === 'after') {
      setFakeCount(prevFakeCount => ({ start: prevFakeCount.start + pageSize, end: prevFakeCount.end + pageSize }))
    }
  }

  function onSortChange (newSortColumn) {
    let newSortDirection = 'desc'
    if (newSortColumn === queryParams.sort.column) {
      newSortDirection = queryParams.sort.direction === 'asc' ? 'desc' : 'asc'
    }

    setFakeCount(null)
    setQueryParamsReplace({
      ...searchParams, sort: { column: newSortColumn, direction: newSortDirection }
    })
  }

  const clearFormValues = () => {
    setFakeCount(null)
    setQueryParamsReplace({})
  }

  const handleJobsSearch = (values) => {
    setFakeCount(null)
    setQueryParamsReplace({ ...values, sort: queryParams.sort })
  }

  useEffect(function onInitialRender () {
    action(REQUEST_ALL_CLIENTS_ACTION)
  }, [user])

  useEffect(function setDefaultQueryParams () {
    const defaultQueryParams = {}
    if (!queryParams.sort) {
      defaultQueryParams.sort = { column: 'customId', direction: 'desc' }
    }
    setQueryParams(defaultQueryParams, 'replaceIn')
  }, [queryParams, setQueryParams])

  useEffect(function toggleFakePagination () {
    if (!data || isFetching || fakeCount !== null) return
    setFakeCount({ start: 1, end: Math.min(pageSize, data?.jobs.totalCount) })
  }, [data, isFetching, fakeCount, setFakeCount])

  const fetching = allClients.isFetching

  return (
    <div>
      <NormalLayoutContainer>
        <div className='container-fluid'>
          <PageTitle>Search Jobs</PageTitle>
          <div className='row'>
            <Spinner isFetching={fetching}>
              <div className='col-xs-12'>
                <div className='panel panel-default row'>
                  <div className='panel-body'>
                    <JobsTableSearchForm
                      clients={allClients.clients}
                      handleSubmit={handleJobsSearch}
                      clearFormValues={clearFormValues}
                      initialFormVals={searchParams}
                    /></div>
                </div>
              </div>
            </Spinner>
          </div>
          <Spinner isFetching={isFetching}>
            <div className='panel panel-default row'>
              <div className='panel-body'>
                {data?.jobs && queryParams.sort &&
                  <JobsTable
                    jobs={data.jobs}
                    sort={queryParams.sort}
                    onPageRequest={onPageRequest}
                    onSortChange={onSortChange}
                    fakeCount={fakeCount}
                  />}
              </div>
            </div>
          </Spinner>
        </div>
      </NormalLayoutContainer>
    </div>
  )
}
